import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import NewButton from '../components/NewButton'
import HowHubbleWorksCarousel from '../components/HowHubbleWorksCarousel'
import { navigate } from 'gatsby'
import '../styles/pages/doctor-portal.scss'

const DoctorPortal = () => {

  return (
    <Layout isDoctorPortal={true} isLogoHeader={true} >
      <SEO title="Doctor Portal" />
      <div className="doctor-portal-wrapper">
        <div className='top-section'></div>
        <div className='welcome-section'>
          <h3 className='welcome-title'>See, <i>Easy.</i></h3>
          <div className='welcome-text'>
          <p>Here at Hubble, we appreciate you choosing our contact lenses. Start here to order trial lenses for your office, or to place an order for a patient. Reach out to us at <a href="mailto:doctor@hubblecontacts.com">doctor@hubblecontacts.com</a> to request a full fitting set.</p>
          </div>
          <div className='btn-wrapper'>
            <NewButton label={"Order Trial Lenses"} color={"purple"} onClick={() => navigate("/doctor-portal/trial-boxes")} />
            <NewButton label={"Order for a Patient"} color={"purple"} onClick={() => navigate("/doctor-portal/patient-order")} />
          </div>
        </div>
        <HowHubbleWorksCarousel title={"Here’s How Hubble Works"} isDoctorPortal={true} />
      </div>
    </Layout>
  )
}

export default DoctorPortal;
